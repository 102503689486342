<template>
  <v-container fluid>
    <patient-filter :panel="true" @filterChange="filterChange" />
    <v-card outlined class="container pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          :v-if="$store.getters.isInRole(30)"
          color="primary"
          class="ma-1"
          elevation="0"
          to="/patientregistration"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>
      <data-table-component
        :headers="headers"
        :items="patients"
        :search="search"
        :loading="loading"
      />
      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      />
    </v-card>
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PatientFilter from "@/components/PatientFilter.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  components: { ConfirmDialog, PatientFilter, DataTableComponent },
  data() {
    return {
      dialogDelete: false,
      loading: true,
      editedIndex: -1,
      search: "",
      filterItem: {
        patientName: "",
        fromDate: this.$moment().format("YYYY-MM-DD"),
        toDate: this.$moment().format("YYYY-MM-DD"),
      },

      patients: [],
      editedItem: {
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: "",
        appointmentStatus: 0,
        note: "",
      },
      defaultItem: {
        //doctorGuid: "00000000-0000-0000-0000-000000000000",
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: "",
        appointmentStatus: 0,
        note: "",
      },
      gender: [
        { text: this.$t("patients.male"), value: 0 },
        { text: this.$t("patients.female"), value: 1 },
      ],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  computed: {
    headers() {
      var list = [
        //{ text: "Id", value: "id" },
        { text: this.$t("patients.patientCode"), value: "patientCode" },
        { text: this.$t("patients.patientName"), value: "patientName" },
        {
          text: this.$t("patients.patientGender"),
          value: "patientGender",
          type: "select",
          items: this.gender,
        },
        { text: this.$t("phone"), value: "patientMobile" },
        { text: this.$t("notes"), value: "patientNote" },
        {
          text: this.$t("date"),
          value: "dateReg",
          type: "date",
          format: "YYYY/MM/DD hh:mm A",
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(30)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(31)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getText(i) {
      var result = this.status.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },

    editItem(item) {
      this.editedIndex = this.patients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$router.push("/patientregistration/" + item.guid);
    },
    deleteItem(item) {
      this.editedIndex = this.patients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.patients[this.editedIndex];

      this.$axios
        .delete("Patient/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.status == 200 && response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      console.log(this.filterItem);
      this.$axios
        .get("Patient?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.patients = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterChange(filterItem) {
      this.loading = true;
      this.filterItem = filterItem;
      this.refreshTable();
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
